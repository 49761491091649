import React from 'react';
import '../../../assets/styles/components/footer.scss';
import logo from '../../../assets/images/blue-logo.webp';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="footer container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 ">
            <img src={logo} width="178" height="22" className="d-inline-block align-top" alt="proFormulate logo" />
            <br />
            <p className="mt-2 p-footer">
              5500 University Pkwy, San Bernardino, CA 92407, United States. <br />
              +1 (951) 407-0422 <br />
              info@proformulate.org
            </p>
          </div>
          <div className="col-12 col-md-4  ">
            <h5>Support</h5>
            <div className="mt-2 p-footer">
             <Link to='/privacy-and-policy'><span>Privacy and Policy</span></Link> 
              <br />
              <span>Terms and Conditions</span>
            </div>
          </div>
          <div className="col-12 col-md-4  ">
            <h5>Company</h5>
            <div className="mt-2 p-footer">
              <span>Plans</span>
              <br />
              <span>Register</span>
            </div>
          </div>
        </div>

        <p className='text-center mt-2 footer-p'>Copyright © 2024 ProFormulate | Powered by ProFormulate</p>
      </div>
    </>
  );
};

export default Footer;
