import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, notification } from 'antd';

const AddUser = () => {
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const response = await fetch('https://c1hbe531.caspio.com/rest/v2/tables/ProFor_User_Definition/records?response=Rows', {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer tEQ19DRpqYTINnxumgy_RTjjBJziWX8T0X1FJjDPqwYHWIlN5AXQ0xT9UEVNtQiBQThtVFZsL3kfdB-zor_o_vi_nR0EHfuuH7co5gMwtn7pWVJe1ZvJo3gDw-wksqnrAumenw5jNzbtOGQbb2RKL8QP9j_t4JqCFnMdQjxOXWBb0C6703Fun-UyYDc96OX8J-wexDARqrClMFT4mF88ewZO24mMvN7Ku2jDYJysqpHOsVt76zAtz2PimRkioCconnyIpSL8IWg2IOcpcgHfr-TKQnBKj-cID3yiAbWeuheV08tVd_my1XnsPTvoaGdNBf-nMfxRDoWcFdNTy2Vki8MR7_zpNX2s-hnn2GWdVj9hZd_4i67D0tQBTpMt5xaS'
            },
            body: JSON.stringify(values)
        });

        if (!response.ok) {
            notification.error({
                message: 'Error',
                description: 'Error adding user'
            });
            return;
        }

        const resultText = await response.text();

        try {
            const result = JSON.parse(resultText);
            notification.success({
                message: 'Success',
                description: 'User added successfully'
            });
            console.log('User added successfully:', result);
        } catch (e) {
            notification.success({
                message: 'Success',
                description: 'User added successfully'
            });
            console.warn('Failed to parse JSON response:', resultText);
        }
    };

    return (
        <Form
            form={form}
            name="addUser"
            onFinish={onFinish}
            layout="vertical"
        >
            <div className="row">
                <div className="col-6">
                    <Form.Item
                        label="Username"
                        name="USR_Username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className="col-6">
                    <Form.Item
                        label="Password"
                        name="USR_Password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                </div>
            </div>

            <div className="row">
                <div className="col-4">
                    <Form.Item
                        label="Email"
                        name="USR_Email"
                        rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className="col-4">
                    <Form.Item
                        label="First Name"
                        name="USR_First_Name"
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className="col-4">
                    <Form.Item
                        label="Last Name"
                        name="USR_Last_Name"
                    >
                        <Input />
                    </Form.Item>
                </div>
            </div>

            <div className="row">
                <div className="col-4">
                    <Form.Item
                        label="City"
                        name="USR_City"
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className="col-4">
                    <Form.Item
                        label="Post Code"
                        name="USR_Post_code"
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className="col-4">
                    <Form.Item
                        label="State"
                        name="USR_State"
                    >
                        <Input />
                    </Form.Item>
                </div>
            </div>

            <div className="row">
                <div className="col-4">
                    <Form.Item
                        label="Phone"
                        name="USR_Phone"
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className="col-4">
                    <Form.Item
                        label="Phone Country Code"
                        name="USR_Phone_country_code"
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className="col-4">
                    <Form.Item
                        label="Google ID"
                        name="USR_Google_ID"
                    >
                        <Input />
                    </Form.Item>
                </div>
            </div>

            <Form.Item
                name="USR_Free_Trial"
                valuePropName="checked"
            >
                <Checkbox className='free-p'>Free Trial</Checkbox>
            </Form.Item>

            <Form.Item
                name="USR_Active"
                valuePropName="checked"
            >
                <Checkbox className='free-p'>Active</Checkbox>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Add User
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddUser;
