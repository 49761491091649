import '../../assets/styles/pages/register.scss';
import Lottie from 'lottie-react';
import registerLottie from '../../assets/lottie/Signup.json';
import { Link } from 'react-router-dom';
import { Divider, message, Steps } from 'antd';
import { useState } from 'react';

const Register = () => {
    const [formData, setFormData] = useState({
        USR_Username: '',
        USR_Password_API: '',
        password_confirmation: '',
        USR_Email: '',
        USR_First_Name: '',
        USR_Last_Name: '',
        USR_City: '',
        USR_Post_code: '',
        USR_State: '',
        USR_Phone: '',
        USR_Phone_country_code: '',
        USR_Google_ID: '',
        USR_Free_Trial: true,
        USR_Country_Code: 'US',
        USR_Active: true,
        USR_email_verified: false,
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(0);

    const steps = [
        {
            title: 'Personal Information',
            content: (
                <>
                    <div className="row mt-4">
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control"
                                name="USR_First_Name"
                                placeholder="First name"
                                value={formData.USR_First_Name}
                                onChange={handleChange}
                                aria-label="First Name"
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control form-design"
                                name="USR_Last_Name"
                                placeholder="Last Name"
                                value={formData.USR_Last_Name}
                                onChange={handleChange}
                                aria-label="Last Name"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <input
                                type="text"
                                className="form-control form-design"
                                name="USR_Username"
                                placeholder="Username"
                                value={formData.USR_Username}
                                onChange={handleChange}
                                aria-label="Username"
                            />
                        </div>
                    </div>
                </>
            ),
        },
        {
            title: 'Contact Information',
            content: (
                <>
                    <div className="row mt-4">
                        <div className="col-6">
                            <input
                                type="email"
                                className="form-control form-design"
                                name="USR_Email"
                                placeholder="Email example@gmail.com"
                                value={formData.USR_Email}
                                onChange={handleChange}
                                aria-label="Email"
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control form-design"
                                name="USR_Phone"
                                placeholder="Phone Number"
                                value={formData.USR_Phone}
                                onChange={handleChange}
                                aria-label="Phone Number"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <input
                                type="password"
                                className="form-control form-design"
                                name="USR_Password_API"
                                placeholder="Password"
                                value={formData.USR_Password_API}
                                onChange={handleChange}
                                aria-label="Password"
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="password"
                                className="form-control form-design"
                                name="password_confirmation"
                                placeholder="Confirm Password"
                                value={formData.password_confirmation}
                                onChange={handleChange}
                                aria-label="Confirm Password"
                            />
                        </div>
                    </div>
                </>
            ),
        },
        {
            title: 'Account Information',
            content: (
                <>
                    <div className="row mt-4">
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control form-design"
                                name="USR_City"
                                placeholder="City"
                                value={formData.USR_City}
                                onChange={handleChange}
                                aria-label="City"
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control form-design"
                                name="USR_State"
                                placeholder="State"
                                value={formData.USR_State}
                                onChange={handleChange}
                                aria-label="State"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control form-design"
                                name="USR_Post_code"
                                placeholder="ZIP Code"
                                value={formData.USR_Post_code}
                                onChange={handleChange}
                                aria-label="ZIP Code"
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control form-design"
                                name="USR_Phone_country_code"
                                placeholder="Country Code"
                                value={formData.USR_Phone_country_code}
                                onChange={handleChange}
                                aria-label="Country Code"
                            />
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const next = () => {
        if (current === steps.length - 1) {
            handleSubmit();
        } else {
            setCurrent(current + 1);
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const items = steps.map(item => ({
        key: item.title,
        title: item.title,
    }));

    const validatePassword = (password, confirmPassword) => {
        if (password !== confirmPassword) {
            message.error('Password does not match the confirmation');
            return false;
        }
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,30}$/;
        if (!passwordRegex.test(password)) {
            message.error('Password must be 8-30 characters long and include at least one letter, one digit, and one special character.');
            return false;
        }
        return true;
    };

    const validateFormData = () => {
        const { USR_Username, USR_Password_API, USR_Email, USR_First_Name, USR_Last_Name, USR_City, USR_State, USR_Post_code, USR_Phone, USR_Phone_country_code } = formData;

        if (!USR_Username || !USR_Password_API || !USR_Email || !USR_City || !USR_State || !USR_Post_code || !USR_Phone || !USR_Phone_country_code) {
            message.error('All fields are required.');
            return false;
        }

        if (!/^[a-zA-Z0-9]+$/.test(USR_Username)) {
            message.error('Username should only contain alphanumeric characters.');
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(USR_Email)) {
            message.error('Invalid email format.');
            return false;
        }

        const phoneRegex = /^[0-9]{10,15}$/;
        if (!phoneRegex.test(USR_Phone)) {
            message.error('Invalid phone number format.');
            return false;
        }

        return validatePassword(USR_Password_API, formData.password_confirmation);
    };

    const apiURL = process.env.NODE_ENV === 'production' ? 'https://yourproductionurl.com/api/auth/register' : 'http://127.0.0.1:8000/api/auth/register';

    const handleSubmit = async () => {
        if (!validateFormData()) return;

        const payload = {
            ...formData,
            USR_First_Last: `${formData.USR_First_Name} ${formData.USR_Last_Name}`,
            USR_Source: 1, 
        };
        setLoading(true);

        try {
            const response = await fetch(apiURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            setLoading(false);

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem("Token", data.authToken);
                message.success('Registration successful');
                localStorage.setItem('user_id', data.user.USR_ID);  
        
                message.success('Login successful. Redirecting to the home page...');
                
                setTimeout(() => {
                    window.location.href =  `/onboarding/${data.user.USR_ID}`;
                }, 2000);
            } else {
                const errorData = await response.json();
                message.error(errorData.error || 'Registration failed');
            }
        } catch (error) {
            message.error('Failed to register');
            setLoading(false);
        }
    };

    return (
        <>
            <div className="register-section">
                <div className='container'>
                    <div className="row">
                        <div className="col-5">
                            <Lottie className="register-logo" animationData={registerLottie} />
                        </div>
                        <div className="col-7 register-design d-flex justify-content-center align-items-center">
                            <form onSubmit={e => {
                                e.preventDefault();
                                if (current === steps.length - 1) {
                                    handleSubmit();
                                } else {
                                    next();
                                }
                            }}>
                                <div className='text-center'>
                                    <h2>Create Account</h2>
                                    <p>Create Your Account Now And Enjoy Our Service</p>
                                </div>

                                <Steps current={current} items={items} />
                                <div className='style-steps'>{steps[current].content}</div>
                                <div className='text-center' style={{ marginTop: 24 }}>
                                    {current < steps.length - 1 && (
                                        <button type="button" className='btn btn-next-register' onClick={next}>
                                            Next
                                        </button>
                                    )}
                                    {current === steps.length - 1 && (
                                        <button type="submit" className='btn btn-done-register' disabled={loading}>
                                            {loading ? 'Creating Account...' : 'Create Account'}
                                        </button>
                                    )}
                                    {current > 0 && (
                                        <button type="button" className='btn btn-previous-register m-3' onClick={prev}>
                                            Previous
                                        </button>
                                    )}
                                </div>

                                <Divider />

                                <span className='text-center d-flex justify-content-center mt-3'>
                                    Already have an account? <Link to='/login'><span className='span-route p-2'>Login</span></Link>
                                </span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
