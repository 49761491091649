import React from 'react';
import '../../../assets/styles/pages/services.scss';
import Lottie from 'lottie-react';
import modelLottie from '../../../assets/lottie/PRO FORMA.json'
import { Link } from 'react-router-dom';



const ServicesSection = () => {
    return (
        <>
            <div className='service-page-style'>
                <div className="container">
                    <div>
                        <div className="d-flex justify-content-center">
                            <div className='pro-bg'>
                                <span>
                                    PRO FORMA
                                </span>
                            </div>
                        </div>
                        <div className="d-flex mt-2 justify-content-center">
                            <div className='pro-p'>
                                <p>
                                    Never miss your future.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row d-flex align-items-center justify-content-center ">
                        <div className="col-6 card-one d-flex align-items-center text-center">
                            <div className=''>
                                <p>“What if” is one of those key turns of phrase that business owners need to think about virtually all the time. What if we made an acquisition? What if we lose that account? What if we get hit with higher taxes?</p>
                            </div>
                        </div>
                        <div className="col-6 card-two d-flex align-items-center text-center">
                            <div className=''>
                                <p>“What if” is one of those key turns of phrase that business owners need to think about virtually all the time. What if we made an acquisition? What if we lose that account? What if we get hit with higher taxes?</p>
                            </div>
                        </div>
                        <Link to='/register'>
                        <button className="btn-pro-try mt-3">Try it now</button>
                       </Link>
                        <div className='d-flex justify-content-center mt-5'>
                    <Lottie className="proForma-logo " animationData={modelLottie} />
                    </div>
                    </div>

                 
                    
                </div>
            </div>
        </>
    );
};

export default ServicesSection;
