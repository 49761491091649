import React from 'react';
import FeaturesSection from '../../components/pages/Landing/FeturesSection';


const Features = () => {
  return (
    <>
      <section>
       <FeaturesSection/>
      </section>
    </>
  );
};

export default Features;
