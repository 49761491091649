import React from 'react';
import PlansCard from '../../components/pages/Landing/PlansCard';


const Plans = () => {
  return (
    <>
      <section>
       <PlansCard/>
      </section>
    </>
  );
};

export default Plans;
