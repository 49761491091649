import React from 'react';
import ModelSection from '../../components/pages/Landing/ModelSection';
const Model = () => {


    return (
        <>
            <section>
                <ModelSection/>
            </section>
        </>
    );
};

export default Model;
