import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

const SystemMainComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      try {

        const response = await fetch('https://c1hbe531.caspio.com/rest/v2/tables/ProFor_User_Definition/records', {
          method: 'GET',
          headers: {
            'accept': 'application/json',
            'Authorization': 'Bearer tEQ19DRpqYTINnxumgy_RTjjBJziWX8T0X1FJjDPqwYHWIlN5AXQ0xT9UEVNtQiBQThtVFZsL3kfdB-zor_o_vi_nR0EHfuuH7co5gMwtn7pWVJe1ZvJo3gDw-wksqnrAumenw5jNzbtOGQbb2RKL8QP9j_t4JqCFnMdQjxOXWBb0C6703Fun-UyYDc96OX8J-wexDARqrClMFT4mF88ewZO24mMvN7Ku2jDYJysqpHOsVt76zAtz2PimRkioCconnyIpSL8IWg2IOcpcgHfr-TKQnBKj-cID3yiAbWeuheV08tVd_my1XnsPTvoaGdNBf-nMfxRDoWcFdNTy2Vki8MR7_zpNX2s-hnn2GWdVj9hZd_4i67D0tQBTpMt5xaS',
          },
        });


        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }


        const jsonData = await response.json();


        setData(jsonData.Result);
      } catch (err) {

        setError(err.message);
      } finally {

        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Define columns for the table
  const columns = [
    {
      title: 'ID',
      dataIndex: 'USR_ID',
      key: 'USR_ID',
    },

    {
      title: 'Username',
      dataIndex: 'USR_Username',
      key: 'USR_Username',
    },
    {
      title: 'Email',
      dataIndex: 'USR_Email',
      key: 'USR_Email',
    },
    {
      title: 'full Name',
      dataIndex: 'USR_First_Last',
      key: 'USR_First_Last',
    },

    {
      title: 'City',
      dataIndex: 'USR_City',
      key: 'USR_City',
    },
    {
      title: 'State',
      dataIndex: 'USR_State',
      key: 'USR_State',
    },
    {
      title: 'Phone',
      dataIndex: 'USR_Phone',
      key: 'USR_Phone',
    },
    {
      title: 'Post code',
      dataIndex: 'USR_Post_code',
      key: 'USR_Post_code',
    },
   
   

  ];

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <Table dataSource={data} columns={columns} rowKey="PK_ID" />
      )}
    </div>
  );
};

export default SystemMainComponent;
