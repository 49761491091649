import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import '../../assets/styles/pages/login.scss';
import Lottie from 'lottie-react';
import loginLottie from '../../assets/lottie/Login.json';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { message, Spin } from 'antd';
import { useState } from 'react';

const Login = () => { 
    const apiURL = `${process.env.REACT_APP_API_BASE_URL}/login`;
    const apiGoogleURL = `${process.env.REACT_APP_API_BASE_URL}/google-login`;
 
    const [loginData, setLoginData] = useState({
        USR_Email: '',
        USR_Password_API: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = e => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    const handleLogin = async e => {
        e.preventDefault();
        if (!loginData.USR_Email || !loginData.USR_Password_API) {
            message.error('Please fill in all fields');
            return;
        }
        setLoading(true);
        try {
            const response = await fetch(apiURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginData)
            });

            setLoading(false);

            if (response.ok) {
                const data = await response.json();
                handleLoginSuccess(data);  
            } else {
                const errorData = await response.json();
                message.error(errorData.message || 'Incorrect email or password');
            }
        } catch (error) {
            setLoading(false);
            message.error('Error during login');
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        const { credential } = credentialResponse;
        if (credential) {
            const decoded = jwtDecode(credential);

            const { email, name: userName, sub } = decoded;

            try {
                const response = await axios.post(apiGoogleURL, {
                    email,
                    userName,
                    google_id: sub
                });

                handleLoginSuccess(response.data);  
            } catch (error) {
                console.error('Login failed:', error.response ? error.response.data : error.message);
                message.error('Error during Google login');
            }
        } else {
            message.error('No credential received');
        }
    };

    const handleLoginSuccess = (data) => {
        localStorage.setItem('Token', data.token);
        localStorage.setItem('user_id', data.user.USR_ID);  

        message.success('Login successful. Redirecting to the home page...');
        
        setTimeout(() => {
            window.location.href =  `/dashboard/${data.user.USR_ID}`;
        }, 2000);
    };

    return (
        <>
            <div className="login-section">
                <div className='container'>
                    <div className="row">
                        <div className="col-6 Login-design d-flex justify-content-center align-items-center">
                            <div>
                                <h2>SIGN IN WITH YOUR PROFORMULATE ACCOUNT</h2>
                                <form className='mt-3' onSubmit={handleLogin}>
                                    <div className="mb-3">
                                        <label htmlFor="formGroupExampleInput" className="form-label">Email</label>
                                        <input type="email" className="form-control" placeholder="Email" name="USR_Email" value={loginData.USR_Email} onChange={handleChange} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="formGroupExampleInput2" className="form-label">Password</label>
                                        <input type="password" className="form-control" placeholder="Password" name="USR_Password_API" value={loginData.USR_Password_API} onChange={handleChange} />
                                    </div>
                                    <div className="col-12 mt-3">
                                        {loading ? (
                                            <Spin />
                                        ) : (
                                            <button type="submit" className='btn btn-submit-login w-100'>Login</button>
                                        )} 
                                    </div>
                                </form>

                                <div className='row mt-3 justify-content-between align-items-center'>
                                    <div className="col-4"><div className='line1'></div></div>
                                    <div className="col-4"><p className='text-center'>Or continue with</p></div>
                                    <div className="col-4"><div className='line1'></div></div>
                                </div>

                                <div className='row mt-3 justify-content-center'>
                                    <div className="col-5">
                                        <GoogleLogin
                                            onSuccess={handleGoogleSuccess}
                                            onError={() => {
                                                message.error('Login Failed');
                                            }}
                                        />
                                    </div>
                                </div>

                                <span className='text-center d-flex justify-content-center mt-3'>
                                    Don't have an account? <Link to='/register'><span className='span-route p-2'>Sign UP</span></Link>
                                </span>
                            </div>
                        </div>
                        <div className="col-6">
                            <Lottie className="login-logo" animationData={loginLottie} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
