import React from 'react';
import logo from '../../../../assets/images/blue-logo.webp';
import { Link } from 'react-router-dom';
import '../../../../assets/styles/components/onBoarding.scss'


const Header = () => {
  return (
    <>
      <nav class="navbar navbar-light  ">
        <div class="container-fluid ">
          <Link to='/' class="navbar-brand m-auto text-center text-header" href="#">
          <img src={logo} width="110" height="15" className="d-inline-block align-top" alt="proFormulate logo" />
              <br /> Create Financial Plan
          </Link>
        </div>
      </nav>
    </>
  );
}

export default Header;
