import React from 'react';
import '../../../assets/styles/pages/faq.scss';
import Lottie from 'lottie-react';
import FAQ from '../../../assets/lottie/FAQ.json'



const FaqSection = () => {
    return (
        <>
            <div className='faq-page-style'>
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-4 ">
                            <Lottie className="vh-logo " animationData={FAQ} />
                        </div>
                        <div className="col-8 p-5">
                            <h2>FAQ</h2>
                            <div className="row justify-content-between">
                                <div className="col-6 FAQ-card p-3">
                                    <span>01.</span>
                                    <h4>Why should cookies be <br /> allowed ?</h4>
                                    <p >To provide the best experiences, we use technologies like cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Not consenting or withdrawing consent, may adversely affect certain features and functions.</p>
                                </div>
                                <div className="col-6 FAQ-card p-3">
                                    <span>02.</span>
                                    <h4>Chrome Browser - Android - Allow Browser Cookies .</h4>
                                    <p > <span className='p-span'>In the Chrome app</span>
                                        <ul>
                                            <li>On your Android phone or tablet, open the Chrome app .</li>
                                            <li>At the top right, tap More   Settings.</li>
                                            <li>Tap Site settings  Third-party cookies.</li>
                                            <li>Select :Allow third-party cookies.</li>


                                        </ul>
                                       </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                            <div className="col-12 FAQ-card-12 p-3">
                                    <span>02.</span>
                                    <h4>How to enable Third-Party Cookies in Your Browser ?</h4>
                                    <p > <span className='p-span'>To enable cookies in Google Chrome (PC or Mac):</span>
                                        <ul>
                                            <li>In Chrome, click the Chrome menu icon (three dots in the upper right)</li>
                                            <li>Click Settings</li>
                                            <li>In the Privacy section, click Cookies and other site data</li>
                                            <li>Make sure that Block third-party cookies and Block all cookies are not selected</li>
                                        </ul>
                                       </p>

                                       <p > <span className='p-span '>To enable cookies in Google Chrome (iPhone or iPad):</span>
                                        <ul>
                                            <li>Open the iOS Settings (gear icon)</li>
                                            <li>Scroll and select Chrome, or swipe down to use the search field</li>
                                            <li>Enable the Allow Cross-Website Tracking slider</li>
                                        </ul>
                                       </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default FaqSection;
