import React from 'react';
import SystemMainComponent from '../../components/pages/System/SystemMainComponent';


const SystemMainPage = () => {
  return (
    <>
       <SystemMainComponent />
    </>
  );
};

export default SystemMainPage;
