import React, { useState, useEffect } from 'react';
import { Link, Outlet, Navigate, useNavigate } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  AppstoreOutlined,
  UserOutlined,
  EditOutlined,
  FolderViewOutlined,
  MoneyCollectOutlined
} from '@ant-design/icons';
import {
  Button,
  Layout,
  Menu,
  message,
  theme,
} from 'antd';

import '../../../assets/styles/components/layout.scss'
import logo from '../../../assets/images/white-logo.webp';
import smallLogo from '../../../assets/images/small.png';


const AuthenticatedRoute = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(() => {
    const user_id = localStorage.getItem('user_id');
    const user_token = localStorage.getItem('Token');
    return user_id && user_token;
  });

  useEffect(() => {
    const user_id = localStorage.getItem('user_id');
    const user_token = localStorage.getItem('Token');
    const isAuthenticated = user_id && user_token;
    setAuthenticated(isAuthenticated);
  }, []);

  if (!authenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const { Header, Sider, Content } = Layout;

const ProtectedRoute = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const userId = localStorage.getItem('user_id');
  const navigate = useNavigate();

  const logout = async () => {
      localStorage.removeItem('Token');
      localStorage.removeItem('user_id');
      navigate('/');
    
  };

  return (
    <>
      <AuthenticatedRoute>

        <Layout >
          <Sider  trigger={null} collapsible collapsed={collapsed}>
            <div  />
            <div className="p-2 text-white">
              {collapsed ?  <img src={smallLogo} width="60" height="22"  className="mt-3" alt="proFormulate logo" /> :<img src={logo} width="178" height="22" className='mt-3' alt="proFormulate logo" /> }
            </div>
            <Menu
              className='left-slide-style'
              mode="inline"
              defaultSelectedKeys={['1']}
              items={[
                
                {
                  key: '1',
                  icon: <HomeOutlined />,
                  label: <Link to={`/dashboard/${userId}`}>Home</Link>,
                },{
                  key: '2',
                  icon: <EditOutlined />,
                  label: <Link to={`/dashboard/${userId}/edit-plan`}>Edit Plan</Link>,
                },
                {
                  key: '3',
                  icon: <FolderViewOutlined />,
                  label: <Link to={`/dashboard/${userId}/view-plan`}>View Plan</Link>,
                },
                {
                  key: '4',
                  icon: <MoneyCollectOutlined />,
                  label: <Link to={`/dashboard/${userId}/financials`}>Financials</Link>,
                },
                {
                  key: '5',
                  icon: <UserOutlined />,
                  label: <Link to={`/dashboard/${userId}/users`}>Users</Link>,
                },
        
        
              ]}
            />
          </Sider>
          <Layout>
            <Header
              
              className=' header-style '
            >
              <div className="d-flex justify-content-between">
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                  }}
                />
                <button className='mt-3 m-4 btn btn-logout' onClick={logout}>Logout</button>
              </div>
            </Header>
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 594,
                background: '#fff',
                borderRadius: borderRadiusLG,
              }}
            >
              <div className="container layout-content">
                <Outlet  />
              </div>
            </Content>
          </Layout>
        </Layout>
      </AuthenticatedRoute>

    </>
  );
};

export default ProtectedRoute;