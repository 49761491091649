import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Header from './Header';



const AuthenticatedRoute = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(() => {
    const user_id = localStorage.getItem('user_id');
    const user_token = localStorage.getItem('Token');
    return user_id && user_token;
  });

  useEffect(() => {
    const user_id = localStorage.getItem('user_id');
    const user_token = localStorage.getItem('Token');
    const isAuthenticated = user_id && user_token;
    setAuthenticated(isAuthenticated);
  }, []);

  if (!authenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};


const ProtectedOnBoarding = () => {
  
  return (
    <>
      <AuthenticatedRoute>

    
            <Header/>
              <div className="layout-content">
                <Outlet  />
              </div>
           
         
      </AuthenticatedRoute>

    </>
  );
};

export default ProtectedOnBoarding;