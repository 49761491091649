import React from 'react';
import FaqSection from '../../components/pages/Landing/FaqSection';


const FAQ = () => {
  return (
    <>
      <section>
       <FaqSection/>
      </section>
    </>
  );
};

export default FAQ;
