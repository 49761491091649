import React from 'react';
import AddUser from '../../components/pages/System/AddUser';


const AddUserPage = () => {
  return (
    <>
       <AddUser />
    </>
  );
};

export default AddUserPage;
