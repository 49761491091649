import React from 'react';
import logo from '../../../assets/images/white-logo.webp';
import { HashLink } from 'react-router-hash-link';
import "../../../assets/styles/components/header.scss"
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <div className="container navbar-style mt-2">
          <img src={logo} width="178" height="22" className="d-inline-block align-top" alt="proFormulate logo" />
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto">
              <li className="nav-item active">
                <Link to="/" className="nav-link">Home</Link>
              </li>
              <li className="nav-item active">
                <HashLink smooth to="#features" className="nav-link">Features</HashLink>
              </li>
              <li className="nav-item active">
                <Link to="/plans" className="nav-link">Plans</Link>
              </li>

              <li className="nav-item active">
                <HashLink smooth to="#contact" className="nav-link">Contact</HashLink>
              </li>
            </ul>
            <Link to='/login'>
              <button className="btn btn-login pr-5">
                Login
              </button>
            </Link>
            <Link to='/register'>
              <button className="btn btn-tryNow">
                Try it now
              </button>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
