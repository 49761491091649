import React from 'react';
import '../../../assets/styles/pages/contactUs.scss';
import Lottie from 'lottie-react';
import contacUs from '../../../assets/lottie/Contact-us.json'
import Footer from '../../Layout/Landing/Footer';


const ContactUsSection = () => {
    return (
        <>
            <div className='contactUs-page-style'>
                <div className="container">
                    <div className="row vh-100 justify-content-between align-items-center">
                        <div className="col-6 p-5">
                            <div className='contact-data'>
                                <span>
                                    Contact-us
                                </span>
                                <h3>We’d love to hear from you.</h3>
                                <p>ProFormulate® is here to provide you with more information, answer any questions you may have and create an effective solution for your instructional needs.</p>
                            </div>

                            <form>
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Name" />
                                    </div>
                                    <div class="col">
                                        <input type="email" class="form-control" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div class="form-group">
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"  placeholder="Message"></textarea>
                                    </div>
                                </div>

                                <button className="btn btn-form-submit mt-3">Send Message</button>
                            </form>

                        </div>
                        <div className="col-6 ">
                            <Lottie className="vh-logo " animationData={contacUs} />
                        </div>

                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactUsSection;
